@import "~styles/includes/index.scss";
.container {
	@extend %container;
}

.wrapper {
	top: 0;
	position: absolute;
	display: grid;
	grid-template-columns: 1fr;
	width: 260px;
	height: 56px;
	z-index: 200;
	opacity: 0;
	background-color: white;

	&,
	> a {
		pointer-events: none;
		opacity: 0;
	}

	&:focus,
	&:focus-within,
	> a:focus {
		opacity: 1;
	}

	> a {
		grid-row-start: 1;
		grid-column-start: 1;
		pointer-events: none;
		padding: 12px 20px;
		@extend %p;
		font-weight: 400;
	}
}
