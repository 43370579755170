@import "~styles/includes/index.scss";
.title {
	font-weight: 500;
	font-size: 32px;
	line-height: 40px;
	margin-bottom: 24px;
}

.schedulingTable {
	width: 100%;
	text-align: left;

	color: $color-primary-blue;

	figure {
		border-radius: 2px;
		margin: 0;
	}

	table {
		width: 100%;

		@media (max-width: $breakpoint-medium) {
			display: none;
		}
	}

	th {
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
	}

	tr {
		border: 1px solid $color-tint-grey-60;
		box-sizing: border-box;
		box-shadow: 0px 3px 0px $color-tint-grey-60;
	}

	th,
	td {
		padding: 16px 24px;
		vertical-align: top;

		p,
		li {
			font-weight: $global-font-weight;
			font-size: 16px;
			line-height: 24px;
		}

		a {
			text-decoration: underline;
		}
	}
}

.grey {
	table,
	.mobileRow {
		@include backgroundGrey();
	}
}

.white {
	table,
	.mobileRow {
		@include backgroundWhite();
	}
}

.mobileTable {
	display: none;

	ul:last-child,
	ol:last-child,
	blockquote:last-child {
		margin-bottom: 0;
	}

	@media (max-width: $breakpoint-medium) {
		display: flex;
		flex-direction: column;
		gap: 16px;

		.mobileRow {
			padding: 16px;
			border: 1px solid $color-tint-grey-40;
			box-sizing: border-box;
			box-shadow: 0px 3px 0px $color-tint-grey-40;

			.mobileHeader {
				font-weight: bolder;
				font-size: 14px;
				line-height: 24px;
			}

			.mobileCell {
				font-size: 12px;
				line-height: 18px;
			}
		}
	}
}
