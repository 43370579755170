@import "~styles/includes/index.scss";
.section {
	background-color: white;
	padding: 40px 0;

	@media (min-width: $breakpoint-small-medium) {
		padding: 56px 0;
	}
}

.container {
	@extend %container;
}

.wrapper {
	max-width: 740px;

	h2 {
		margin-bottom: 16px;

		@media (min-width: $breakpoint-small-medium) {
			margin-bottom: 24px;
		}
	}

	form {
		margin-bottom: 20px;
		@include text-field-style();

		@media (min-width: $breakpoint-small-medium) {
			margin-bottom: 48px;
		}

		button {
			min-width: 140px;

			@media (max-width: $breakpoint-small-medium) {
				margin-top: 16px;
			}

			@media (min-width: $breakpoint-small-medium) {
				margin-left: auto;
			}
		}
	}

	form > div:not(:last-child) {
		margin-bottom: 16px;
	}

	form > div:last-child {
		@extend %p;
		@media (min-width: $breakpoint-small-medium) {
			display: flex;
			flex-direction: row;
			gap: 40px;
		}
	}

	textarea {
		min-height: 100px;
		border-radius: 2px;
	}
}

.head {
	margin-bottom: 8px;
	vertical-align: middle;
}

.date::before {
	content: '•';
	margin: 0 5px;
}

.success,
.message {
	margin-bottom: 16px;
	padding: 18px 10px;
	border-radius: 2px;
	background-color: $color-tint-grey-80;
	@extend %p;

	@media (min-width: $breakpoint-small-medium) {
		padding: 24px;
	}
}

.preview {
	opacity: 0.5;
}

.success {
	background-color: $color-info-success;
	margin-bottom: 20px;

	@media (min-width: $breakpoint-small-medium) {
		margin-bottom: 42px;
	}
}
