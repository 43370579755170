@mixin tertiary-light-button {
	&.tertiary-light {
		a {
			padding: 0px;
			height: auto;
			background: transparent;
			color: #ffffff;
			font-size: 16px;
			border-radius: 0;
			border: 0;
			font-weight: $global-font-weight;

			&:not(:disabled) {
				&:hover {
					text-decoration: underline;
				}

				&:active {
					text-decoration-line: underline;
				}
			}

			&:disabled {
				opacity: 0.5;
				cursor: not-allowed;
			}
		}
	}
}
