@import "~styles/includes/index.scss";
.section {
	position: relative;

	table,
	[class*='table-module_mobileRow__'] {
		@include backgroundWhite();
	}

	&.overflow {
		overflow: hidden;

		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			z-index: 1;
			width: 16px;

			@media (min-width: 1400px) {
				width: 8%;
			}
		}

		&::before {
			--deg: -90deg;
			left: 0;
		}

		&::after {
			--deg: 90deg;
			right: 0;

			@media (max-width: 1399px) {
				width: calc(10% + 16px);
			}
		}

		// can't use css variables with SCSS rgba function
		&.backgroundWhite::before,
		&.backgroundWhite::after {
			background: linear-gradient(var(--deg), rgba(white, 0) 0%, white 100%);
		}

		&.backgroundGrey::before,
		&.backgroundGrey::after {
			background: linear-gradient(var(--deg), rgba($color-tint-grey-80, 0) 0%, $color-tint-grey-80 100%);
		}

		&.backgroundPurple::before,
		&.backgroundPurple::after {
			background: linear-gradient(
				var(--deg),
				rgba($color-primary-blue-non-variable, 0) 0%,
				$color-primary-blue-non-variable 100%
			);
		}
	}
}

.padding {
	&None {
		@include paddingNone();
	}
	&Small {
		@include paddingSmall();
	}
	&Medium {
		@include paddingMedium();
	}
	&Large {
		@include paddingLarge();
	}
}

.background {
	&White {
		@include backgroundWhite();

		table,
		[class*='table-module_mobileRow__'] {
			@include backgroundGrey();
		}
	}
	&Gray {
		@include backgroundGrey();
	}
	&Purple {
		@include backgroundPurple();
		color: white;
	}
}
