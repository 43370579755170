@import "~styles/includes/index.scss";
.items {
	.item {
		margin-top: 16px;
	}

	.title {
		color: $color-primary-blue;
		font-weight: 500;
		display: flex;
		align-items: center;

		a {
			font-weight: 500;
		}

		.icon {
			margin-left: 16px;
			margin-top: 2px;
		}
	}
}
