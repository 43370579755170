@import "~styles/includes/index.scss";
.icon {
	display: inline-flex;
}

.customIcon {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	svg {
		stroke: currentcolor;
		fill: currentcolor;
		stroke-width: 0;

		path {
			stroke: currentcolor;
			fill: currentcolor;
		}
	}
}
