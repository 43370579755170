@import "~styles/includes/index.scss";
$top-nav-height: 56px;
$bottom-nav-height: 64px;

.navBarItem {
	height: 100%;
}

.fixed {
	width: 100%;
	z-index: 100;
	background-color: white;
	position: fixed;
	transform: translateY(0px);
	transition: transform 0.15s ease-in-out;
	top: 0;
}

.hideSticky {
	transform: translateY(-($top-nav-height + $bottom-nav-height));
}

.searchIcon {
	display: inherit;

	svg path {
		fill: $color-tint-grey-25;
		stroke: $color-tint-grey-25;
	}
}

.topBar {
	height: 56px;
	background: $color-primary-blue;
	font-size: 16px;
	padding: 8px 0px;
	color: white;

	.content {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		max-width: 1200px;
		margin: 0 auto;
		height: 100%;
		padding: 0 16px;

		> div {
			flex-grow: 1;
			width: 33%;
		}

		.left {
			display: flex;
			font-size: 20px;

			img {
				cursor: pointer;
			}

			@media (max-width: 390px) {
				img {
					height: 12px;
					max-width: 100%;
				}
			}

			.label {
				font-size: 20px;
				line-height: 25px;
				font-weight: $global-font-weight;

				cursor: pointer;
			}
		}

		.screenReader {
			margin-left: 40px;
		}

		.middle {
			display: flex;
			justify-content: center;

			form {
				width: 100%;
			}

			.searchLabel {
				@extend %screenreader;
			}

			@media (max-width: $breakpoint-medium) {
				display: none;
			}
		}

		.right {
			display: flex;
			justify-content: flex-end;
			font-weight: $global-font-weight;
			height: 100%;
			align-items: center;

			.navBarItem:not(:last-child) {
				margin-right: 40px;
			}

			.contact {
				@media (max-width: $breakpoint-medium) {
					display: none;
				}
			}

			.language {
				@media (max-width: $breakpoint-medium) {
					display: none;
				}

				margin-left: 40px;
			}

			.mobileMenu {
				height: 100%;
				display: flex;
				align-items: center;
			}
		}
	}
}

.bottomBar {
	height: 64px;
	background: white;
	box-shadow: 0px 1px 0px $color-tint-grey-80;
	display: flex;
	justify-content: center;

	@media (max-width: $breakpoint-medium) {
		display: none;
	}

	.content {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		height: 100%;
		padding: 0 16px;
		max-width: 1200px;
		width: 100%;

		> div {
			width: 50%;
		}

		.left {
			display: flex;
			flex: 1 0;
			font-size: 20px;
			height: 100%;
			align-items: center;

			.navBarItem {
				align-items: center;
			}
		}

		.right {
			display: flex;
			flex: 0;

			.end {
				justify-content: flex-end;
			}

			span {
				display: flex;
				align-items: center;
				white-space: nowrap;
			}

			span:not(:last-child) {
				margin-right: 40px;
			}
		}
	}
}

.mobileSearch {
	padding: 6px 8px;
	background: $color-tint-grey-60;

	@media (min-width: $breakpoint-medium) {
		display: none;
	}
}

.searchBar {
	position: relative;
	display: flex;
	flex-direction: row;

	@media (max-width: $breakpoint-small-max) {
		input {
			padding: 12px 16px;
			height: auto;
		}
	}

	input[type='search']::-webkit-search-cancel-button {
		margin-right: 25px;
	}
}

.searchBarIconDesktop,
.searchBarIconMobile {
	@include button-reset;
	cursor: pointer;
	display: inherit;
	position: absolute;
	top: 50%;
	right: 16px;
	translate: 0 -50%;
}
