@import "~styles/includes/index.scss";
.linkWrapper {
	&:hover {
		text-decoration: underline;

		.docReader {
			svg {
				path {
					fill: $font-color-hover;
				}
			}
		}
	}

	.link {
		font-weight: 350;
		font-size: 18px;
		line-height: 32px;
		text-decoration: underline;

		@media (max-width: $breakpoint-small-medium) {
			font-weight: 350;
			font-size: 14px;
			line-height: 24px;
		}
	}
}
