@import "~styles/includes/index.scss";
.layout {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.main {
	flex-grow: 1;
}
