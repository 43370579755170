@import "~styles/includes/index.scss";
$selectIconBlack: 'data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=';
$selectIconGrey: 'data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6Izk5OTk5OTt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=';
$backgroundTransition: background-color 0.15s;
$boxShadowTransition: box-shadow 0.15s;

@-moz-document url-prefix() {
	select.select:not(.fixCrossBrowserStyling) {
		box-shadow: none;
	}
}

.customSelect {
	position: relative;
	display: flex;
	z-index: 10;

	select {
		width: 100%;
	}
}

.fixCrossBrowserStyling {
	background: url($selectIconBlack);
	background-repeat: no-repeat;
	background-position: 100% 50%;
	background-size: 26px 26px;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	padding: 0 8px;
	padding-right: 24px;
	background-color: white;
	box-sizing: border-box;
	height: 20px;
	font-size: 12px;
	line-height: 12px;
	border-radius: 4px;

	&::-moz-focus-inner,
	&::-moz-focus-outer {
		border: none;
	}

	&.sizeMedium {
		padding: 0 12px;
		background-size: 28px 28px;
		font-size: 14px;
		line-height: 14px;
		padding-right: 28px;
	}

	&.sizeBig {
		padding: 0 16px;
		background-size: 32px 32px;
		font-size: 16px;
		line-height: 16px;
		padding-right: 32px;
	}

	&:disabled {
		background-image: url($selectIconGrey);
	}
}

.select {
	position: relative;
	display: block;

	&.sizeMedium {
		height: 24px;
	}

	&.sizeBig {
		height: 32px;
	}

	&.fullWidth {
		width: 100%;
	}

	&:focus {
		transition: $boxShadowTransition;
	}

	&:-moz-focusring {
		color: transparent;
		text-shadow: 0 0 0 #000;
	}

	&:disabled {
		cursor: not-allowed;
	}

	&.error {
		background-size: 100% 100%;

		&:hover {
			box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.08), 0 3px 2px -2px rgba(0, 0, 0, 0.08),
				inset 0 0 0 1px rgba(255, 0, 0, 1);
		}
	}
}

.placeholder {
	color: #9d9d9d;
}

.option {
	color: #000;

	&:disabled {
		color: rgba(#000, 0.5);
	}
}
