@import "~styles/includes/index.scss";
.previewMode {
	padding: 8px 16px;
	position: fixed;
	bottom: 0;
	margin: 0 auto;
	background-color: $color-secondary-blue;
	color: white;
	width: 100%;
	max-width: 1200px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.link {
	padding: 6px 16px !important;
	border: 1px solid white !important;
	color: white;
	font-size: 16px;
	line-height: 32px;

	&:hover {
		color: #f44336;
	}
}
