@import "~styles/includes/index.scss";
.Dropdown {
	height: 100%;
	display: block;
	padding-right: 40px;
}

.button {
	display: flex;
	height: 100%;
	align-items: center;
}

.label {
	margin-right: 6px;
}

.icon {
	margin-bottom: -2px;
}

.collapsibleMenu {
	transition: transform ease-out 0.3s;
	left: 0;
	width: 100%;
	display: flex;
	justify-content: center;
	background: white;
	position: absolute;
	z-index: 10;
	visibility: hidden;
	box-shadow: 0px 1px 0px $color-tint-grey-80, 0px -1px 0px $color-tint-grey-80;
	transition: all 0s ease;
	transition-delay: 250ms;

	&.isOpen {
		visibility: visible;
	}

	.content {
		width: 1200px;
		height: 100%;
		padding-top: 48px;
		padding-bottom: 48px;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 2rem;

		.contentGroup {

			&,
			>a {
				font-size: 16px;
				color: $color-primary-blue;
			}

			h1 {
				margin: inherit !important;
				font-size: inherit !important;
				line-height: inherit !important;

				&,
				>a {
					font-size: 16px;
					color: $color-primary-blue;
				}
			}

			>a {
				-bottom: 16px;
				font-weight: 500;
			}

			a,
			div {
				margin-bottom: 8px;
			}

			.link {
				margin-top: 8px;
			}
		}
	}
}

.hasLink {
	div:first-of-type {
		cursor: pointer;

		&:hover {
			text-decoration-line: underline;
			color: $font-color-hover;
		}
	}
}