@import "~styles/includes/index.scss";
%focus-within {
	outline: solid $color-primary-blue 2px; // Outline to prevent border resize
	outline-offset: -4px; // Minus offset to prevent overflow hidden cutoff
}

.section {
	overflow: hidden;
	max-width: 740px;

	.itemHeader {
		user-select: none;
		cursor: pointer;
		display: flex;
		width: 100%;
		padding: 24px 24px 8px 24px;
		align-items: center;
		outline: none;

		&:focus-visible {
			@extend %focus-within;
		}

		.icon {
			margin-left: auto;
			height: 16px;
		}

		.itemTitle {
			font-size: 18px;
			line-height: 32px;

			@media (max-width: $breakpoint-medium) {
				font-size: 14px;
				line-height: 24px;
			}
		}

		@media (max-width: $breakpoint-medium) {
			padding: 16px;
		}
	}

	.icon {
		animation: rotate 0.5s;
		color: $font-color-main;
	}

	.description {
		padding: 0 24px 0;
		visibility: hidden;

		a {
			font-size: 16px;
			text-decoration: $color-primary-blue underline;
		}

		p,
		li {
			font-size: 16px;
			line-height: 24px;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}

			@media (max-width: $breakpoint-medium) {
				font-size: 14px;
			}
		}

		blockquote,
		q {
			margin: 0px 16px;
			margin: 0px 16px 32px 16px;
			position: relative;
			quotes: '“' '”' '‘' '’';
			font-weight: $global-font-weight;
			font-size: 32px;

			&::before {
				left: -16px;
				position: absolute;
				content: open-quote;
			}

			&::after {
				position: absolute;
				content: close-quote;
			}

			p {
				display: inline;
				font-weight: $global-font-weight;
				font-size: 18px;
				line-height: 40px;
			}
		}

		ol {
			margin: 0px 0px 32px 0px;
			padding: 0px 16px;
			list-style: decimal;
		}

		ul {
			margin: 0px 0px 32px 0px;
			padding: 0px 18px;
		}

		li {
			line-height: 32px;
			font-weight: $global-font-weight;
			font-size: 18px;

			a {
				font-size: 17px;
				line-height: 32px;
				text-decoration: underline;
			}
		}

		img {
			width: auto !important;
		}

		table,
		[class*='table-module_mobileRow__'] {
			@include backgroundWhite();
		}

		@media (max-width: $breakpoint-medium) {
			padding: 0 16px 16px 16px;
		}
	}

	[aria-expanded='true'] ~ .description {
		visibility: visible;
	}

	&.openSection {
		.itemHeader .itemTitle {
			color: $font-color-hover;
		}
	}

	.accordeonName {
		font-size: 18px;
		line-height: 32px;
		font-weight: 500;
		color: #1b143c;

		@media (max-width: $breakpoint-medium-max) {
			font-size: 14px;
			line-height: 24px;
		}
	}
}

.item {
	border: 1px solid $color-tint-grey-40;
	box-shadow: 0px 3px 0px $color-tint-grey-40;
	border-radius: 2px;
	margin: 16px 0;
	overflow: hidden;
	transition: height linear 0.15s;
	max-width: 740px;

	.itemHeader {
		user-select: none;
		cursor: pointer;
		display: flex;
		width: 100%;
		padding: 24px;
		align-items: center;
		outline: none;

		&:focus-visible {
			@extend %focus-within;
		}

		.icon {
			margin-left: auto;
			height: 16px;
		}

		.itemTitle {
			font-size: 18px;
			line-height: 32px;

			@media (max-width: $breakpoint-medium) {
				font-size: 14px;
				line-height: 24px;
			}
		}

		@media (max-width: $breakpoint-medium) {
			padding: 16px;
		}
	}

	.icon {
		animation: rotate 0.5s;
		color: $font-color-main;
	}

	.description {
		padding: 0 24px 24px;

		a {
			font-size: 16px;
			text-decoration: $color-primary-blue underline;
		}

		p,
		li {
			font-size: 16px;
			line-height: 24px;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}

			@media (max-width: $breakpoint-medium) {
				font-size: 14px;
			}
		}

		@media (max-width: $breakpoint-medium) {
			padding: 16px;
		}
	}

	&.openItem {
		.itemHeader .itemTitle {
			color: $font-color-hover;
		}

		.icon {
			animation: rotateCounter 0.5s;
			color: $font-color-hover;
		}
	}
}

@keyframes rotate {
	to {
		transform: rotate(-180deg);
	}
}

@keyframes rotateCounter {
	to {
		transform: rotate(180deg);
	}
}

.grey {
	@include backgroundGrey();
}

.white {
	@include backgroundWhite();
}

.row {
	display: grid;

	@media (max-width: $breakpoint-medium) {
		display: block;
	}
}

.rowFullWidth {
	grid-template-columns: 1fr;

	@media (max-width: $breakpoint-medium) {
		grid-template-columns: 1fr;
	}
}

.rowWithSidebar {
	grid-template-columns: 740px 1fr;

	@media (max-width: $breakpoint-medium) {
		grid-template-columns: 1fr;
	}
}

.content {
	width: 100%;
}

.sidebar {
	margin-left: 80px;
	margin-top: 32px;

	ul {
		list-style-image: url('/assets/icons/chevron-right.svg');
		font-size: 18px;
		padding-left: 20px;
	}

	li {
		padding-bottom: 11px;
	}

	@media (max-width: $breakpoint-medium) {
		margin-left: 0px;

		.itemTitle {
			font-size: 18px;
		}

		ul {
			margin-bottom: 0px;

			span {
				font-size: 14px;
				line-height: 24px;
			}
		}
	}
}
