@import "~styles/includes/index.scss";
$breakpoint-footer-medium: 990px;

.Footer {
	position: relative;
	width: 100%;
	display: flex;
	padding: 90px 0px;
	background: $color-tint-grey-60;
	flex-wrap: wrap;

	a {
		&:hover {
			text-decoration: underline;
		}
	}

	@media (max-width: $breakpoint-large) {
		padding: 40px 0px;
	}

	.container {
		flex: 0 1 calc(100% - 32px);
		margin-left: 8px;

		@media (min-width: $breakpoint-medium) {
			width: 100%;
			max-width: 1200px;
			margin: 0 0 0 calc((100% - 1200px) / 2);
			flex: unset;
		}
	}
}

.wrapper {
	position: relative;
}

.columns {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 1rem;

	@media (max-width: $breakpoint-footer-medium) {
		grid-template-columns: repeat(2, 1fr);
		width: 100%;
	}

	@media (max-width: $breakpoint-small) {
		grid-template-columns: none;
		width: 100%;
		display: flex;
		flex-direction: column;
		grid-gap: 0;
	}
}

.row {
	margin-bottom: 40px;

	&:not(:last-child) {
		margin-right: 80px;
	}

	h3 {
		color: $color-primary-blue;
		margin-bottom: 20px;
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			&:not(:last-child) {
				margin-bottom: 12px;
			}
		}
	}
}

.iconLink {
	display: flex;
	align-items: center;
	font-size: 16px;
	color: $color-primary-blue;

	.icon {
		position: relative;
		height: 24px;
		width: 24px;
		margin-right: 12px;
	}
}

.bottomLinks {
	display: flex;
	align-items: center;
	justify-content: center;
	border-top: solid $color-primary-blue 1px;
	padding-top: 16px;
	padding-bottom: 26px;

	@media (max-width: $breakpoint-large) {
		margin: 0 0 150px 0;
		padding-bottom: 0;
		justify-content: left;
	}

	ul {
		display: flex;
		font-weight: 500;
		list-style: none;
		padding: 0;
		margin: 0;

		@media (max-width: $breakpoint-footer-medium) {
			margin: 16px 0 0 0;
			flex-direction: column;
		}

		li {
			display: flex;
			align-items: center;

			&:not(:first-child) {
				margin: 0px 0px 0px 40px;
			}

			@media (max-width: $breakpoint-footer-medium) {
				align-items: flex-start;
				flex-direction: column;

				&:not(:first-child) {
					margin: 16px 0px 0px 0px;
				}
			}

			@media (max-width: $breakpoint-large) {
				&:nth-child(2) {
					margin: 0px;
				}
			}
		}
	}
}

.logo {
	align-self: flex-end;
	margin-bottom: -3px;
	margin-left: auto;
	margin-right: 8px;

	&,
	img {
		width: clamp(180px, 15%, 250px);
	}

	img {
		height: 90px;

		@media (max-width: $breakpoint-large) {
			height: 65px;
		}
	}

	@media (max-width: $breakpoint-large) {
		position: absolute;
		right: 0;
		display: flex;
		justify-content: flex-end;
		bottom: 0px;
		margin-bottom: 40px;
		min-width: 0;
	}
}

.accesibilityLogoDesktop {
	min-width: 35px;

	@media (max-width: $breakpoint-large) {
		display: none !important;
		min-width: 0;
	}
}

.accesibilityLogoMobile {
	position: absolute;
	left: 16px;
	bottom: 40px;

	@media (min-width: $breakpoint-large) {
		display: none !important;
	}
}
