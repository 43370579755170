@import "~styles/includes/index.scss";
.noteContainer {
	position: fixed;
	bottom: 0;
	margin: 0 auto;
	color: white;
	width: 100%;
	max-width: 1200px;
	z-index: 100;

	.header {
		padding: 12px 18px;
		background-color: $color-primary-blue;

		display: flex;

		justify-content: space-between;
		align-items: center;

		width: 100%;
	}

	.content {
		transition: height ease-in-out 0.3s;
		padding: 8px 24px 24px 24px;
		color: $color-primary-blue;
		background-color: white;
		border: 1px solid $color-tint-grey-60;
		overflow-y: scroll;
	}
}

.sizeSelect {
	select {
		color: $color-tint-grey-0 !important;
	}
}
