@import "~styles/includes/index.scss";
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	@extend %heading;
}

.h1 {
	@extend %h1;
}

.h2 {
	@extend %h2;
}

.h3 {
	@extend %h3;
}

.h4 {
	@extend %h4;
}

.h5 {
	@extend %h5;
}

.h6 {
	@extend %h6;
}

.p {
	@extend %p;
}

.b,
.strong {
	@extend %b;
}

.small {
	@extend %small;
}

.card {
	@extend %card;
}

.screenreader {
	@extend %screenreader;
}

.intro {
	font-weight: 500;
	font-size: 22px;
	line-height: 32px;

	@media (max-width: $breakpoint-medium-max) {
		font-size: 18px;
		line-height: 24px;
	}
}

.left {
	text-align: left;
}

.center {
	text-align: center;
}

.right {
	text-align: right;
}

.justify {
	text-align: justify;
}
