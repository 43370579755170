@import "~styles/includes/index.scss";
.LanguageSwitcher {
	.button button:disabled {
		opacity: 1;
		text-decoration: underline;
	}

	&.dark {
		color: $color-primary-blue;
	}

	&.light {
		color: white;
	}
}

.link {
	padding: 0px;
	height: auto;
	background: transparent;
	color: #ffffff;
	font-size: 16px;
	border-radius: 0;
	border: 0;
	font-weight: $global-font-weight;

	&:not(:disabled) {
		&:hover {
			color: white;
			text-decoration: underline;
		}

		&:active {
			text-decoration-line: underline;
		}
	}

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}

	@media (max-width: $breakpoint-medium) {
		color: $color-primary-blue;
	}
}
