@import "~styles/includes/index.scss";
.mobileMenu {
	display: block;
	align-items: center;
	padding-left: 16px;
	border-left: 1px solid $color-tint-grey-20;
	margin: 0 16px 0 16px;
	height: 100%;

	@media (min-width: $breakpoint-medium) {
		display: none;
	}
}

.menuButton {
	@include button-reset();
	cursor: pointer;
	display: flex;
	height: 100%;
	align-items: center;
	color: white;
	font-weight: $global-font-weight;

	.title {
		margin-right: 8px;
	}

	.icon {
		margin-top: 4px;
	}
}

.menu {
	margin-top: 17px;
	left: 0;
	width: 100%;
	display: flex;
	background: white;
	position: absolute;
	z-index: 12;
	position: fixed;
	box-sizing: border-box;
	overflow: hidden;
	height: 0px;
	display: flex;

	transition: height 0.15s ease-out;

	&.isOpen {
		height: calc(100vh - 56px);
	}

	.content {
		padding: 24px 16px 16px 16px;
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		overflow: hidden;
	}

	.items {
		color: $font-color-main;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	.item {
		margin-bottom: 12px;
		min-height: 24px;
	}

	.bottomItems {
		display: flex;
		flex-direction: column;

		.languageSwitcher {
			margin-top: 16px;
		}
	}

	button {
		min-height: auto;

		> [class*='icon'] {
			width: 12px;
			height: 20px;
		}

		> [class*='title'] {
			line-height: 16px;
		}
	}
}
