@import "~styles/includes/index.scss";
.item {
	border-bottom: 1px solid $color-tint-grey-20;

	border-radius: 2px;
	overflow: hidden;
	transition: height ease-in-out 0.3s;

	.itemHeader {
		user-select: none;
		cursor: pointer;
		display: flex;
		width: 100%;
		padding: 16px 16px 16px 0px;
		align-items: center;

		.icon {
			margin-left: auto;
			height: 16px;
		}

		.itemTitle {
			font-size: 18px;
			line-height: 32px;

			@media (max-width: $breakpoint-medium) {
				font-size: 14px;
				line-height: 24px;
			}
		}

		@media (max-width: $breakpoint-medium) {
			padding: 16px;
		}
	}

	.icon {
		color: $font-color-main;
	}

	.description {
		padding: 0 24px 24px;

		strong {
			font-size: 15px;
		}

		p,
		li {
			font-size: 16px;
			line-height: 24px;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}

			@media (max-width: $breakpoint-medium) {
				font-size: 14px;
			}
		}

		@media (max-width: $breakpoint-medium) {
			padding: 16px;
		}
	}

	&.openItem {
		.itemHeader h4 {
			color: $font-color-hover;
		}

		.icon {
			color: $font-color-hover;
		}
	}
}

.grey {
	@include backgroundGrey();
}

.white {
	@include backgroundWhite();
}

.row {
	display: grid;

	@media (max-width: $breakpoint-medium) {
		display: block;
	}
}

.rowFullWidth {
	grid-template-columns: 1fr;

	@media (max-width: $breakpoint-medium) {
		grid-template-columns: 1fr;
	}
}

.rowWithSidebar {
	grid-template-columns: 740px 1fr;

	@media (max-width: $breakpoint-medium) {
		grid-template-columns: 1fr;
	}
}

.content {
	width: 100%;
}

.label {
	display: block;
	font-size: 14px;
	line-height: 32px;
}
